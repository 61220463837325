import Marquee from "react-fast-marquee";
import Audi from "../../../assets/Audi_Rings_bl-RGB.png";
import Perudo from "../../../assets/images (1).png";
import MG from "../../../assets/images.png";

function Protfolio() {
  return (
    <div className=' mb-24 w-screen mt-24 lg:mt-32 relative border-b '>
      <div>
        <div>
          <h1 className='text-center text-4xl font-bold font-mooli'>
            Our Valuable Clients
          </h1>
          <div
            className='
                 h-24 w-full text-center text-xl mt-5 mb-10 font-Montserrat  '
          >
            <p>
              We are pleased to serve one of the most reputed and respected
              brands across different sectors globally.
            </p>
          </div>
        </div>

        <div className=' mt-10'>
          <Marquee>
            <img src={Audi} height={240} width={240} className='pl-10' />
            <img src={Perudo} height={440} width={440} />
            <img src={MG} height={140} width={140} />
            <img src={Audi} height={240} width={240} className='pl-16' />
            <img src={Perudo} height={440} width={440} />
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default Protfolio;

import logo from "./logo.svg";
import {
  Header,
  Hero,
  About,
  ServicesProtfolio,
  Services,
  ProtfolioCovers,
  Protfolio,
  Contact,
  Footer,
} from "./components/layout";
import "./App.css";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <About />
      <ServicesProtfolio />
      <Services />
      <ProtfolioCovers />
      <Protfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

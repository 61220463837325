"use client";
import React, { useMemo } from "react";

import ScrollAnimationWrapper from "../ScrollAnimationWrapper/ScrollAnimationWrapper";
import ButtonOutline from "../../misc/ButtonOutline.";
import SoftawarePng from "../../../assets/coding.png";
import ECUImage from "../../../assets/download.png";
import Verification from "../../../assets/verfication.png";
import { motion } from "framer-motion";
import { getScrollAnimation } from "../../../utils/getScrollAnimation";
import { scrollAnimation2 } from "../../../utils/getScrollAnimation";
import getScrollAnimation2 from "../../../utils/ScrollAnimation2";

const services = [
  {
    title: "   Integration of and third-party products",
    image: SoftawarePng,
  },
  {
    title: " E C U Development and training  ",
    image: ECUImage,
  },
  {
    title: "Verification and validation services.",
    image: Verification,
  },
];

const servicesDescriptions = [
  {
    key: 1,
    title: "Integration of and third-party products",
    image: SoftawarePng,

    classColor: "bg-secondoryBg",
    description:
      "VincoLab takes a central role in making the separation of hardware and software work for car makers, effectively managing the software development process for increasingly complex systems as well as for software and system integration.",
    class: "bg-buttonColor",
  },

  {
    key: 2,
    title: " E C U Development and training  ",
    image: ECUImage,
    classColor: "bg-buttonColor",

    description:
      "We are keen to share our in-depth know-how about selected methods and technologies, as well as providing product trainings. Our trainers work closely with our development department which helps them to always stay up-to-date and to be able to immediately incorporate newly-acquired knowledge and current trends in their courses.",

    flreverse: "flex-row-reverse",
    class: "bg-buttonColor",
  },

  {
    key: 3,
    title: "Verification and validation services.",
    image: Verification,
    gap: "mt-24 lg:mt-0",
    classColor: "bg-secondoryBg",
    description:
      " As a premium AUTOSAR member, VincoLab has successfully tackled the challenges of complex systems validation, e.g. integrated high-performance ECUs, with a strong, reliable, but also flexible test strategy – highest requirement levels, shortened time to market, and mass production.",

    class: "bg-buttonColor",
  },
];

function Services() {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);
  const lefttoright = useMemo(() => scrollAnimation2(), []);
  const righttoright = useMemo(() => getScrollAnimation2(), []);

  return (
    <>
      <div
        className='w-screen relative h-screen py-8 flex pt-48 lg:pt-0 mt-24 mb-0  flex-col'
        id='services'
      >
        <ScrollAnimationWrapper>
          <motion.div variants={scrollAnimation}>
            <div className='flex flex-col items-center justify-center px-9 md:px-36 xl:px-96 mb-10'>
              <h1 className='text-3xl font-mooli font-bold'>Our Services</h1>
              <p className='text-lg text-center mt-5'>
                We offer end-to-end services in the IT domain ranging from
                customized mobile app development to enterprise web development.
              </p>
            </div>
          </motion.div>
        </ScrollAnimationWrapper>

        <div className='w-screen px-24 xl:px-48  flex flex-col items-center justify-between lg:flex-row '>
          {services.map((item) => (
            <ScrollAnimationWrapper key={item.index}>
              <motion.div
                variants={scrollAnimation}
                whileHover={{
                  scale: 1.1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className='w-80 h-96 custom-shadow  cursor-pointer flex flex-col pb-10  py- rounded-lg my-7 lg:w-72 lg:h-80'
              >
                <div className='flex justify-center items-center mt-10 h-24'>
                  <img src={item.image} width={64} height={64} />
                </div>

                <div className='text-center mt-2'>
                  <p className='text-lg mx-6 font-mooli'>
                    <strong>{item.title}</strong>
                  </p>
                </div>
                <div className='flex items-center justify-center  mt-10'>
                  <ButtonOutline>Learn more</ButtonOutline>
                </div>
              </motion.div>
            </ScrollAnimationWrapper>
          ))}
        </div>
      </div>
      <div className='pt-96 lg:pt-0'>
        <section className=' overflow-x-hidden w-screen lg:top-0  mt-96 mb-32 lg:mb-10 pt-72 lg:mt-0 lg:pt-0 '>
          {servicesDescriptions.map((item) => (
            <ScrollAnimationWrapper>
              <motion.div
                variants={item.key === 2 ? righttoright : lefttoright}
                className={` ${item.gap} w-screen  flex flex-col items-center relative   px-24 justify-between`}
                key={item.key}
              >
                <div
                  className={`${item.flreverse} w-screen flex  items-center justify-between h-max lg:h-96`}
                >
                  <div className='w-4/12 hidden  h-96 lg:flex items-center justify-center'>
                    <img src={item.image} width={264} height={264} />
                  </div>
                  <div className={`w-screen lg:w-8/12 pt-10 lg:pl-24  h-96 `}>
                    <h1
                      className={`text-2xl text-center px-5 lg:text-left font-mooli ${item.textcolor}`}
                    >
                      <strong>{item.title}</strong>
                    </h1>

                    <div className={`${item.class} h-2 w-64 mt-2 `}></div>

                    <div className='text-center lg:text-left w-screen px-10 lg:w-3/4 mt-10'>
                      <p className={`${item.textcolor} text-lg`}>
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </ScrollAnimationWrapper>
          ))}
        </section>
      </div>
    </>
  );
}

export default Services;

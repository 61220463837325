"use client";
import React from "react";

import LogoVPN from "../../../assets/VincoLabLogo1.png";
import Facebook from "../../../assets/facebook.svg";
import Twitter from "../../../assets/twitter.svg";
import Instagram from "../../../assets/instagram.svg";
import { Link as LinkScroll } from "react-scroll";

const currentTime = new Date();
const year = currentTime.getFullYear();

function Footer() {
  return (
    <footer className=' bg-secondoryBg shadow '>
      <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
        <div className='sm:flex sm:items-center sm:justify-between'>
          <a href='/' className='flex items-center mb-4 sm:mb-0'>
            <img src={LogoVPN} width={28} height={28} />
            <span className='self-center  text-2xl font-semibold whitespace-nowrap '>
              VincoLab
            </span>
          </a>
          <ul className='flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400'>
            <li>
              <LinkScroll
                activeClass='active'
                to='home'
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative"
                }
              >
                Home
              </LinkScroll>
            </li>
            <li>
              <LinkScroll
                activeClass='active'
                to='about'
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative"
                }
              >
                About
              </LinkScroll>
            </li>
            <li>
              <LinkScroll
                activeClass='active'
                to='services'
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative"
                }
              >
                Services
              </LinkScroll>
            </li>
            <li>
              <LinkScroll
                activeClass='active'
                to='contact'
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative"
                }
              >
                Contact
              </LinkScroll>
            </li>
          </ul>
        </div>
        <hr className='my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8' />
        <span className='block text-sm text-gray-500 sm:text-center dark:text-gray-400'>
          © {year}{" "}
          <a href='/' className='hover:underline'>
            VincoLab Private LTD
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";

const ButtonOutline = ({ children }) => {
  return (
    <button className='font-medium tracking-wide py-2 px-5 sm:px-8 border border-buttonColor text-buttonColor bg-white-500 outline-none rounded-l-full rounded-r-full capitalize hover:bg-buttonColor  hover:text-white-500 transition-all hover:shadow-orange hover:text-white'>
      {" "}
      {children}
    </button>
  );
};

export default ButtonOutline;

import React, { useState, useEffect } from "react";
import { Link as LinkScroll } from "react-scroll";
import Logo from "../../../assets/VincoLabLogo1.png";

export default function Home() {
  const [navbar, setNavbar] = useState(false);

  const [activeLink, setActiveLink] = useState(null);
  const [scrollActive, setScrollActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollActive(window.scrollY > 20);
    });
  }, []);

  return (
    <div
      className={
        "fixed top-0 z-50 flex items-center overflow-x-hidden w-screen transition-all duration-500" +
        (scrollActive ? " shadow-md pt-0" : " pt-4")
      }
    >
      <nav className='w-full bg-white'>
        <div className='justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8'>
          <div>
            <div className='flex items-center justify-between py-3 md:py-5 md:block'>
              <div className='flex items-center justify-center'>
                <img src={Logo} alt='Logo' width={32} height={32} />
                <a href='#'>
                  <h2 className='text-2xl text-black font-bold font-mooli'>
                    <span className='text-buttonColor'>Vinco</span>Lab
                  </h2>
                </a>
              </div>
              <div className='md:hidden'>
                <button
                  className='p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border'
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='w-6 h-6 text-black'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path
                        fillRule='evenodd'
                        d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='w-6 h-6 text-black'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M4 6h16M4 12h16M4 18h16'
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 transition-all duration-500 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className='w-10 flex-col items-center justify-start space-y-8 md:flex md:flex-row md:w-full md:space-x-6 md:space-y-0 text-lg'>
                <LinkScroll
                  activeClass='active'
                  to='home'
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={1000}
                  onSetActive={() => {
                    setActiveLink("home");
                    setNavbar(false);
                  }}
                  className={
                    "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                    (activeLink === "home"
                      ? "  text-buttonColor animation-active "
                      : " text-black-500 hover:text-buttonColor a")
                  }
                >
                  Home
                </LinkScroll>

                <LinkScroll
                  activeClass='active'
                  to='about'
                  spy={true}
                  smooth={true}
                  duration={1000}
                  onSetActive={() => {
                    setActiveLink("about");
                    setNavbar(false);
                  }}
                  className={`px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative ${
                    activeLink === "about"
                      ? "text-buttonColor animation-active"
                      : "text-black-500 hover:text-buttonColor"
                  }`}
                >
                  About
                </LinkScroll>
                <LinkScroll
                  activeClass='active'
                  to='services'
                  spy={true}
                  smooth={true}
                  duration={1000}
                  offset={-100}
                  onSetActive={() => {
                    setActiveLink("pricing");
                    setNavbar(false);
                  }}
                  className={`px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative ${
                    activeLink === "pricing"
                      ? "text-buttonColor animation-active"
                      : "text-black-500 hover:text-buttonColor"
                  }`}
                >
                  Services
                </LinkScroll>
                <LinkScroll
                  activeClass='active'
                  to='contact'
                  spy={true}
                  smooth={true}
                  duration={1000}
                  offset={-100}
                  onSetActive={() => {
                    setActiveLink("contact");
                    setNavbar(false);
                  }}
                  className={`px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative ${
                    activeLink === "contact"
                      ? "text-buttonColor animation-active"
                      : "text-black-500 hover:text-buttonColor"
                  }`}
                >
                  Contact
                </LinkScroll>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

"use client";
import React, { useState } from "react";

import ContactUs from "../../../assets/vecteezy_male-customer-support-phone-operator-with-headset-working-in_.jpg";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import { motion, useAnimation } from "framer-motion";

function Contact() {
  const currentTime = new Date();
  const year = currentTime.getFullYear();

  const buttonAnimationControls = useAnimation();
  const [isSending, setIsSending] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
    year: year,
    agreeTerms: false,
  });

  const isFormValid = () => {
    return (
      formData.firstName.trim() !== "" &&
      formData.lastName.trim() !== "" &&
      formData.phoneNumber.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.message.trim() !== "" &&
      formData.agreeTerms
    );
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const buttonAnimation = {
    scale: isSending ? 0.8 : 1,
    transition: {
      duration: 0.2, // Adjust the duration as needed
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      toast.error(
        "Please fill out all required fields and agree to the terms."
      );
      return;
    }

    const emailServiceId = "service_qrmv3fm";
    const templateId = "template_ffm04zz";
    const userId = "OHarXFxCeMb-NPVtv";

    setIsSending(true);

    try {
      await emailjs.send(emailServiceId, templateId, formData, userId);
      toast.success("Successfully sent!");
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Email could not be sent. Please try again later.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className='w-screen text-black pt-10 pb-24' id='contact'>
      <Toaster position='top-center' reverseOrder={false} gutter={8} />
      <div className='w-screen text-center'>
        <h1 className='font-mooli text-3xl font-extrabold'>Contact Us</h1>
      </div>
      <div className='flex mt-16 flex-col lg:flex-row'>
        <div className='lg:w-1/2 flex items-center justify-center'>
          <img src={ContactUs} width={600} height={600} alt='contact' />
        </div>

        <div className='px-5'>
          <form onSubmit={handleSubmit}>
            <div className='grid gap-6 mb-6 md:grid-cols-2'>
              <div>
                <label
                  htmlFor='first_name'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  First name
                </label>
                <input
                  type='text'
                  id='first_name'
                  name='firstName'
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='John'
                  required
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label
                  htmlFor='last_name'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  Last name
                </label>
                <input
                  type='text'
                  id='last_name'
                  name='lastName'
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='Doe'
                  required
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label
                  htmlFor='phone'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  Phone number
                </label>
                <input
                  id='phone'
                  name='phoneNumber'
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='+94 7123456789'
                  required
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className='mb-6'>
              <label
                htmlFor='email'
                className='block mb-2 text-sm font-medium text-gray-900'
              >
                Email address
              </label>
              <input
                type='email'
                id='email'
                name='email'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='john.doe@company.com'
                required
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className='mb-6'>
              <label
                htmlFor='message'
                className='block mb-2 text-sm font-medium text-gray-900'
              >
                Message
              </label>
              <textarea
                id='message'
                name='message'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Your message'
                required
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>

            <div className='flex items-start mb-6'>
              <div className='flex items-center h-5'>
                <input
                  id='remember'
                  type='checkbox'
                  name='agreeTerms'
                  className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                  required
                  checked={formData.agreeTerms}
                  onChange={handleInputChange}
                />
              </div>
              <label className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                I agree with the{" "}
                <a
                  href='#'
                  className='text-blue-600 hover:underline dark:text-blue-500'
                >
                  terms and conditions
                </a>
                .
              </label>
            </div>
            <motion.button
              type='submit'
              className='text-white bg-buttonColor focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:focus:ring-blue-800'
              variants={buttonAnimation}
              animate={buttonAnimationControls}
              onClick={handleSubmit}
              disabled={isSending}
            >
              {isSending ? "Sending..." : "Submit"}
            </motion.button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;

"use client";
import React, { useMemo } from "react";
import { motion } from "framer-motion";
import ScrollAnimationWrapper from "../ScrollAnimationWrapper/ScrollAnimationWrapper";
import { getScrollAnimation } from "../../../utils/getScrollAnimation";
import { BsTools, BsWindowDock } from "react-icons/bs";
import { TbSettingsCode } from "react-icons/tb";
import { BiSolidNetworkChart } from "react-icons/bi";
import {
  MdOutlineAutoMode,
  MdDesignServices,
  MdAutoAwesome,
} from "react-icons/md";
import { HiCode } from "react-icons/hi";
import { GiCycle, GiSatelliteCommunication } from "react-icons/gi";
import { CiHardDrive } from "react-icons/ci";

const covers = [
  {
    icon: <BsTools size={32} color={"#fff"} />,
    title: "Software development according to the ASPIC",
  },
  {
    icon: <TbSettingsCode size={32} color={"white"} />,
    title:
      "Configuration and integration of software units using several toolchains and platforms",
  },
  {
    icon: <BiSolidNetworkChart size={32} color={"white"} />,
    title: "Develop algorithms related to all system features and requirements",
  },
  {
    icon: <MdOutlineAutoMode size={32} color={"white"} />,
    title: "Integration of multiple AUTOSAR software layers",
  },
  {
    icon: <HiCode size={32} color={"#fff"} />,
    title: "Model-Based Development and Auto Code Generation",
  },
  {
    icon: <BsWindowDock size={32} color={"#fff"} />,
    title: "Configuration and integration of BSW standard services",
  },
  {
    icon: <GiCycle size={32} color={"#fff"} />,
    title:
      "Design and development of software that is related to the system life cycle in the area of automotive using Model-Based design",
  },
  {
    icon: <GiSatelliteCommunication size={32} color={"#fff"} />,
    title: "Configuration of communication protocols",
  },
  {
    icon: <CiHardDrive size={32} color={"#fff"} />,
    title: "Specification and implementation of Complex Device Driver (CDD)",
  },
  {
    icon: <MdDesignServices size={32} color={"#fff"} />,
    title:
      "Validating that each software unit provides evidence for compliance of the software units with the software detailed design",
  },
  {
    icon: <MdAutoAwesome size={32} color={"#fff"} />,
    title: "Design and development of software in the area of automotive",
  },
];

function ProtfolioCovers() {
  const animation = useMemo(() => getScrollAnimation());

  return (
    <div className=' sm:pt-16 lg:pt-0 bg-secondoryBg py-16'>
      <ScrollAnimationWrapper>
        <motion.div variants={animation} className='pt-16 mt-16 mb-16'>
          <h1 className=' px-10 lg:px-0 text-center text-3xl font-mooli font-extrabold'>
            Our service portfolio covers
          </h1>
        </motion.div>
      </ScrollAnimationWrapper>
      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 items-center gap-6 place-items-center lg:px-24  '>
        {covers.map((item, index) => (
          <ScrollAnimationWrapper key={index}>
            <motion.div
              className=' custom-shadow cursor-pointer rounded-3xl bg-white  text-center px-6 flex h-64 items-center justify-center w-64  '
              variants={animation}
              whileHover={{
                scale: 1.1,
                transition: {
                  duration: 0.3,
                },
              }}
            >
              <div>
                <div className='flex h-10  items-center justify-center mb-6'>
                  <div className='w-16 h-16 bg-circleBg shadow-lg rounded-full flex items-center justify-center'>
                    {item.icon}
                  </div>
                </div>
                <p className='h-16 '>{item.title}</p>
              </div>
            </motion.div>
          </ScrollAnimationWrapper>
        ))}
      </div>
    </div>
  );
}

export default ProtfolioCovers;

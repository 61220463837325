"use client";
import { useRef, useMemo } from "react";
import ScrollAnimationWrapper from "../ScrollAnimationWrapper/ScrollAnimationWrapper";
import { scrollAnimation2 } from "../../../utils/getScrollAnimation";
import getScrollAnimation2 from "../../../utils/ScrollAnimation2";
import { AnimatePresence, motion } from "framer-motion";

function About() {
  const scrollAnimation = useMemo(() => getScrollAnimation2(), []);
  const scrollAnimation33 = useMemo(() => scrollAnimation2(), []);
  return (
    <div
      className='w-screen h-screen bg-secondoryBg lg:px-36 flex  items-center'
      id='about'
    >
      <ScrollAnimationWrapper>
        <div className='pt-24 w-full h-full flex flex-col  lg:flex-row justify-between pb-6'>
          <motion.div
            variants={scrollAnimation}
            className='pt-10 w-full px-5  xl:w-1/2 '
          >
            <div>
              <h1 className='text-4xl font-mooli font-extrabold'>
                <span className='text-buttonColor font-Montserrat'>Vinco</span>
                Lab Private Limited
              </h1>
            </div>
            <div className='h-1 w-24 bg-buttonColor mt-6'></div>

            <div className='mt-10'>
              <p>
                VincoLab offers a complete solution for building secure, and
                flexible ECU (VCU, TCU, BMS, and other controller units)
                architecture, software based on AUTOSAR, ISO 26262, and A-Spice
                standards into the Multi-Core CPUs, MCAL. The general methods
                for the development process are application/base software
                development, model-based software design, V-SDLC, and so on at
                RTE, OS, and SWC levels. With a broad range of know-how, we know
                the needs of global automakers. We help our customers deliver
                state-of-the-art ECU software that meets the specific
                requirements of each automaker.
              </p>
              <p></p>
            </div>
          </motion.div>
          <motion.div
            variants={scrollAnimation33}
            className='w-1/2 hidden  xl:flex justify-center items-center transform pl-20'
          >
            <div className='flex justify-center -mr-12 items-center'>
              <div className='hex absolute'></div>
              <div className='w-24'>
                <h1 className={` text-center font-mooli`}>Experts</h1>
              </div>
            </div>
            <motion.div className='flex justify-center absolute -mt-48 -mr-6 items-center'>
              <div className='hex absolute'></div>
              <div className='w-24'>
                <h1 className={` text-center `}>Best Service</h1>
              </div>
            </motion.div>
            <div className='flex justify-center mt-48 ml-32 items-center'>
              <div className='hex absolute'></div>
              <div className='w-24'>
                <h1 className={` text-center`}>Reliability</h1>
              </div>
            </div>
            <div className='ml-28 flex inclusive-sans justify-center items-center'>
              <div className='hex2 absolute'></div>
              <div className='w-full'>
                <h1 className={`w-12  font-inclusive`}>
                  Best Client Retention Ratio
                </h1>
              </div>
            </div>
          </motion.div>
        </div>
      </ScrollAnimationWrapper>
    </div>
  );
}

export default About;

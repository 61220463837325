"use client";
import { PiMonitorBold } from "react-icons/pi";
import { TbPuzzle } from "react-icons/tb";
import { FaPenToSquare } from "react-icons/fa6";
import { BiGitBranch } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { HiCode } from "react-icons/hi";
import { FaHandshake } from "react-icons/fa";

const data = [
  {
    name: "Requirement Analyse",
    icon: <FaPenToSquare size={32} color={"#fff"} />,
  },
  {
    name: "Architectural Design",
    icon: <TbPuzzle size={32} color={"#fff"} />,
  },
  {
    name: "Modelling and Simulation",
    icon: <PiMonitorBold size={32} color={"#fff"} />,
  },

  {
    name: "Diagnostic Applications",
    icon: <BiGitBranch size={32} color={"#fff"} />,
  },

  {
    name: "System Optimiziation",
    icon: <AiFillStar size={32} color={"#fff"} />,
  },

  {
    name: "Control System Algorithm Development",
    icon: <HiCode size={32} color={"#fff"} />,
  },

  {
    name: "Development ,Test and Integration",
    icon: <FaHandshake size={32} color={"#fff"} />,
  },
];

function ServiceProtfolio() {
  return (
    <div className='h-full  w-screen  flex items-center justify-center bg-white lg:bg-secondoryBg relative mb-96 pb-96 lg:mb-0 lg:pb-10  '>
      <div>
        <h1></h1>
      </div>

      <div className='flex flex-col lg:flex-row w-10/12 h-48 justify-between items-center  lg:px-12 space-x-4'>
        {data.map((item, index) => (
          <div
            key={index}
            className='flex mt-10 flex-col w-32  items-center space-y-2'
          >
            <div className='bg-circleBg h-16 w-16 items-center flex justify-center rounded-full'>
              {item.icon}
            </div>
            <div className='text-center h-10 w-screen lg:w-full'>
              <h1>{item.name}</h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceProtfolio;

"use client";
import React, { useMemo } from "react";
import ButtonPrimary from "../../misc/ButtonPrimary";
import { motion } from "framer-motion";
import { getScrollAnimation } from "../../../utils/getScrollAnimation";
import ScrollAnimationWrapper from "../ScrollAnimationWrapper/ScrollAnimationWrapper";
import HeroImage from "../../../assets/hero_VincoLabs.jpg";
import { Link as LinkScroll } from "react-scroll";

const Hero = () => {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);

  return (
    <div
      className='max-w-screen-xl min-h-screen flex justify-center items-center pt-24 pb-10  px-8 xl:px-16 mx-auto'
      id='home'
    >
      <ScrollAnimationWrapper>
        <motion.div
          className='grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-flow-col md:grid-cols-1 lg:grid-rows-1 sm:grid-cols-2 gap-0  lg:gap-12 '
          variants={scrollAnimation}
        >
          <div className='  row-start-2 sm:row-start-1 lg:pt-24 '>
            <h1 className='text-4xl lg:text-5xl xl:text-6xl font-medium font-mooli text-black-600 leading-normal'>
              <strong>
                <span className=' text-buttonColor'>Vinco</span>Lab
              </strong>
              .
            </h1>
            <p className='text-black-500 mt-4 mb-6 font-Montserrat'>
              Fully integrated ECU software development and integration covering
              Basic Software Development (BSW), Application Software Development
              (ASW), Battery Management System (BMS), Vehicle Control Unit
              (VCU), Motor / Traction Control Unit (MCU, TCU), Transmission
              Control Unit (TCU), Energy Conversion Systems, Energy Management
              Systems, Rapid Prototyping – RPC
            </p>
            <ButtonPrimary>
              <LinkScroll
                activeClass='active'
                to='contact'
                spy={true}
                smooth={true}
                duration={1000}
                offset={-100}
              >
                Click to Contact
              </LinkScroll>
            </ButtonPrimary>
          </div>
          <div className='flex w-full'>
            <motion.div
              className='h-full w-full pt-20 lg:pt-0'
              variants={scrollAnimation}
            >
              <img
                src={HeroImage}
                alt='HeroImage'
                quality={100}
                width={612}
                height={383}
              />
            </motion.div>
          </div>
        </motion.div>
      </ScrollAnimationWrapper>
    </div>
  );
};

export default Hero;
